<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-edit fa-mdr"></i> <span class="h5"> Estadísticas</span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL REGISTRO DE GUÍAS Y PERSONAL:
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-male fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listGuiasPersonal.length}}</span>
                                <br>
                                <span class="text-muted">Registro de guías y personal</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Gestion de Registro de guias y personal</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <!-- <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name:'Proyectos'}">
                                    <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                                </b-button>
                            </b-col> -->
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" @click="modalRegistrarGuiasPersonal = true">
                                    <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Registro</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Listado de Guías y Personal registrados </span>
                        <b-button v-if="listGuiasPersonal.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listGuiasPersonal" :fields="camposQuejas" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(nombreGuiaPrincipal)="data">
                                        <template v-if="listUsuarios.find(x => x.idGuia == data.item.idGuia) == null">
                                            {{''}}
                                        </template>
                                        <template v-else>
                                            {{listUsuarios.find(x => x.idGuia == data.item.idGuia).nombre | truncate(50)}}
                                        </template>
                                        
                                    </template>
                                    <template v-slot:cell(nombrePersonal)="data">
                                        {{data.item.nombre}}
                                    </template>
                                    <template v-slot:cell(nrodocPersonal)="data">
                                        {{data.item.nroDoc}}
                                    </template>
                                    <template v-slot:cell(tipoPersonal)="data">
                                        {{listTipoPersonal.find(x => x.id == data.item.idTipoPersonal).tipo}}
                                    </template>
                                    <template v-slot:cell(fechaViaje)="data">
                                        <!-- {{data.item.fechaViaje}} -->
                                        {{formatearFecha(data.item.fechaViaje,'DD-MM-YYYY')}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarGuiaPersonal(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarGuiaPersonal(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>

        <CModal :show.sync="modalRegistrarGuiasPersonal" size="xl" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Nuevo Registro de Guias y Personal</span>
                </h6>
                <CButtonClose @click="modalRegistrarGuiasPersonal = false" class="text-white" />
            </template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(registrarGuiaPersonal)">
                    <b-row>
                        <b-col md="12">
                            <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                <b-form-group label="Guia Principal:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuiaPersonal.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuiaPersonal.idGuia" :options="listUsuarios" @search:blur="blurGuiaPersonal">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="numero de documento del personal" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Número de documento del Personal:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Número de Documento" v-model.trim="datosGuiaPersonal.nrodocPersonal"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre personal" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre Personal:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre" v-model.trim="datosGuiaPersonal.nombrePersonal"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo personal" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo Personal:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listTipoPersonal => listTipoPersonal.id" label="tipo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuiaPersonal.tipoPersonal , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuiaPersonal.tipoPersonal" :options="listTipoPersonal" @search:blur="blurTipoPersonal">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="fecha de viaje" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha de Viaje:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosGuiaPersonal.fechaViaje"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="observación" v-slot="validationContext">
                                <b-form-group label="Observación (Opcional):" class="mb-2">
                                    <b-form-textarea v-model="datosGuiaPersonal.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese la Observación" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button variant="danger" @click="modalRegistrarGuiasPersonal = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>

        <CModal :show.sync="modalActualizarGuiasPersonal" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                    <span class="h5"> Actualizar Guia Personal</span></h6>
                <CButtonClose @click="modalActualizarGuiasPersonal = false" class="text-white" />
            </template>
            <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(actualizarGuiaPersonal)">
                    <b-row>
                        <b-col md="12">
                            <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                <b-form-group label="Guia Principal:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarGuiaPersonal.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarGuiaPersonal.idGuia" :options="listUsuarios" @search:blur="blurActualizarGuiaPersonal">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="numero de documento del personal" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Número de documento del Personal:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Número de Documento" v-model.trim="datosActualizarGuiaPersonal.nrodocPersonal"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre personal" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre Personal:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre" v-model.trim="datosActualizarGuiaPersonal.nombrePersonal"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo personal" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo Personal:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listTipoPersonal => listTipoPersonal.id" label="tipo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarGuiaPersonal.tipoPersonal , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarGuiaPersonal.tipoPersonal" :options="listTipoPersonal" @search:blur="blurActualizarTipoPersonal">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="fecha de viaje" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha de Viaje:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosActualizarGuiaPersonal.fechaViaje"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="observación" v-slot="validationContext">
                                <b-form-group label="Observación (Opcional):" class="mb-2">
                                    <b-form-textarea v-model="datosActualizarGuiaPersonal.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese la Observación" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Modificar
                            </b-button>
                            <b-button variant="danger" @click="modalActualizarGuiasPersonal = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');
const dbGuiasPersonal = firebase.firestore().collection('guiasYPersonal');
const dbTipoPersonal = firebase.firestore().collection('tipoPersonal');
import XLSX from 'sheetjs-style-v2';
export default {
    data() {
        return {
            listGuiasPersonal: [],
            listUsuarios: [],
            listTipoPersonal: [],
            disabled: false,
            camposQuejas: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombreGuiaPrincipal",
                    label: "Nombre de Guia Principal",
                    class: "text-center",
                },
                {
                    key: "nombrePersonal",
                    label: "Nombre Personal",
                    class: "text-center",
                },
                {
                    key: "nrodocPersonal",
                    label: "Nro. Doc. Personal",
                    class: "text-center",
                },
                {
                    key: "tipoPersonal",
                    label: "Tipo de Personal",
                    class: "text-center",
                },
                {
                    key: "fechaViaje",
                    label: "Fecha Viaje",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],
            filasTotales: 1,
            paginaActual: 1,

            modalRegistrarGuiasPersonal: false,
            modalActualizarGuiasPersonal: false,

            datosGuiaPersonal: {
                idGuia: '',
                nombrePersonal: '',
                nrodocPersonal: '',
                tipoPersonal: '',
                fechaViaje: moment().format('YYYY-MM-DD'),
                observacion: '',
            },

            datosActualizarGuiaPersonal: {
                id: '',
                idGuia: '',
                nombrePersonal: '',
                nrodocPersonal: '',
                tipoPersonal: '',
                fechaViaje: '',
                observacion: '',
            },
        }
    },
    methods: {
        exportDataToExcel() {
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };
            
            // const valDate = date => {
            //     if (date && date !== undefined) {
            //         let dateValidation = moment(date, 'DD-MM-YYYY', true);
            //         if (dateValidation.isValid()) {
            //             return dateValidation
            //         } else {
            //             console.log('else?');
            //             return date
            //         }
            //     }
            //     return '';
            // };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel = this.listGuiasPersonal.map(data => {
                return {
                    A: valUpper(this.listUsuarios && this.listUsuarios !== undefined ? 
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia).length > 0 ? 
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia)[0].nombre : '' : ''),
                    B: valUpper(data['nroDoc']),
                    C: valUpper(data['nombre']),
                    D: valUpper(this.listTipoPersonal && this.listTipoPersonal !== undefined ? 
                        this.listTipoPersonal.filter(x => x.id == data.idTipoPersonal).length > 0 ? 
                        this.listTipoPersonal.filter(x => x.id == data.idTipoPersonal)[0].tipo : '' : ''),
                    E: valDate(data['fechaViaje']),
                    F: valUpper(data['observacion']),
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [
                {
                    s: {
                        r: 1,
                        c: 1,
                    },
                    e: {
                        r: 1,
                        c: 3,
                    },
                }
            ];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 30, //A
                },
                {
                    wch: 15, //B
                },
                {
                    wch: 25, //C
                },
                {
                    wch: 20, //D
                },
                {
                    wch: 15, //E
                },
                {
                    wch: 40, //F
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for (let i of [
                {
                    f: 'B2',
                    v: 'CHECK LIST GUÍAS Y PERSONAL',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NOMBRE DEL GUÍAS',
                },
                {
                    f: 'B4',
                    v: 'NRO. DOCUMENTO',
                },
                {
                    f: 'C4',
                    v: 'NOMBRE DEL PERSONAL',
                },
                {
                    f: 'D4',
                    v: 'TIPO DE CATEGORIA',
                },
                {
                    f: 'E4',
                    v: 'FECHA DE VIAJE',
                },
                {
                    f: 'F4',
                    v: 'OBSERVACIÓN',
                },
            ]){
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'CHECK LIST GUÍAS Y PERSONAL');
            XLSX.writeFile(wb, `CHECK LIST GUÍAS Y PERSONAL ${now}.xlsx`);
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length;
            this.paginaActual = 1;
        },
        formatearFecha(fecha,formato){
            return moment(fecha).format(formato);
        },
        blurGuiaPersonal() {
            this.computedForms.refs['guia principal'].validate();
        },
        blurTipoPersonal() {
            this.computedForms.refs['tipo personal'].validate();
        },
        blurActualizarGuiaPersonal() {
            this.computedFormActualizar.refs['guia principal'].validate();
        },
        blurActualizarTipoPersonal() {
            this.computedFormActualizar.refs['tipo personal'].validate();
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });
        },
        listarTipoPersonal() {
            let me = this;
            dbTipoPersonal
            .where("estado","==",2)
            .onSnapshot((querySnapshot) => {
                me.listTipoPersonal = [];
                querySnapshot.forEach((doc)=> {
                    me.listTipoPersonal.push({
                        id: doc.id,
                        tipo: doc.data().tipo,
                    });
                });
            });
        },
        listarGuiaPersonal() {
            let me = this;
            dbGuiasPersonal
            .where("estado", "==", 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listGuiasPersonal = [];
                querySnapshot.forEach((doc)=> {
                    me.listGuiasPersonal.push({
                        id: doc.id,
                        nombre: doc.data().nombre,
                        nroDoc: doc.data().nroDoc,
                        idGuia: doc.data().idGuia,
                        estado: doc.data().estado,
                        fechaCreacion: doc.data().fechaCreacion,
                        fechaViaje: doc.data().fechaViaje,
                        idCliente: doc.data().idCliente,
                        idTipoPersonal: doc.data().idTipoPersonal,
                        observacion: doc.data().observacion,
                    });
                });
                me.listGuiasPersonal.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        eliminarGuiaPersonal(param) {
            let me = this;
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el Personal?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbGuiasPersonal.doc(param.item.id).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        me.disabled = false;
                    })
                    .catch((error) => {
                        me.disabled = false;
                        me.swat('error', 'Algo salió mal!')
                    });

                }
            });
        },
        registrarGuiaPersonal() {
            let me = this;
            me.disabled = true;
            dbGuiasPersonal.add({
                idGuia: me.datosGuiaPersonal.idGuia.trim(),
                nombre: me.datosGuiaPersonal.nombrePersonal.trim(),
                nroDoc: me.datosGuiaPersonal.nrodocPersonal.trim(),
                idTipoPersonal: me.datosGuiaPersonal.tipoPersonal.trim(),
                observacion: me.datosGuiaPersonal.observacion.trim(),
                idCliente: me.$store.state.user.idCliente.trim(),
                fechaViaje: me.datosGuiaPersonal.fechaViaje.trim(),
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaEliminar: '',
                fechaModificacion: '',
                estado: 2,
            }).then((check) =>{
                me.swat('success', 'Registrado satisfactoriamente')
                me.modalRegistrarGuiasPersonal = false
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        abrirModalActualizarGuiaPersonal(param){
            let me = this;
            
            me.datosActualizarGuiaPersonal.id = param.item.id;
            me.datosActualizarGuiaPersonal.idGuia = param.item.idGuia;
            me.datosActualizarGuiaPersonal.nombrePersonal = param.item.nombre.trim();
            me.datosActualizarGuiaPersonal.nrodocPersonal = param.item.nroDoc.trim();
            me.datosActualizarGuiaPersonal.tipoPersonal = param.item.idTipoPersonal;
            me.datosActualizarGuiaPersonal.fechaViaje = param.item.fechaViaje.trim();
            me.datosActualizarGuiaPersonal.observacion = param.item.observacion.trim();

            me.modalActualizarGuiasPersonal = true;
        },
        actualizarGuiaPersonal() {
            let me = this;
            me.disabled = true;
            dbGuiasPersonal.doc(me.datosActualizarGuiaPersonal.id).update({
                idGuia: me.datosActualizarGuiaPersonal.idGuia,
                nroDoc: me.datosActualizarGuiaPersonal.nrodocPersonal.trim(),
                nombre: me.datosActualizarGuiaPersonal.nombrePersonal.trim(),
                idTipoPersonal: me.datosActualizarGuiaPersonal.tipoPersonal,
                fechaViaje: me.datosActualizarGuiaPersonal.fechaViaje,
                observacion: me.datosActualizarGuiaPersonal.observacion.trim(),
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
            }).then((doc)=> {
                me.swat('success', 'Modificado satisfactoriamente')
                me.modalActualizarGuiasPersonal = false
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!')
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarGuiasPersonal() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

            this.datosGuiaPersonal.idGuia = '';
            this.datosGuiaPersonal.nombrePersonal = '';
            this.datosGuiaPersonal.nrodocPersonal = '';
            this.datosGuiaPersonal.tipoPersonal = '';
            this.datosGuiaPersonal.fechaViaje = moment().format('YYYY-MM-DD');
            this.datosGuiaPersonal.observacion = '';
        },
        resetModalActualizarGuiasPersonal() {
            this.$nextTick(() => {
                this.$refs.observer1.reset();
            });

            this.datosActualizarGuiaPersonal.id = '';
            this.datosActualizarGuiaPersonal.idGuia = '';
            this.datosActualizarGuiaPersonal.nombrePersonal = '';
            this.datosActualizarGuiaPersonal.nrodocPersonal = '';
            this.datosActualizarGuiaPersonal.tipoPersonal = '';
            this.datosActualizarGuiaPersonal.fechaViaje = moment().format('YYYY-MM-DD');
            this.datosActualizarGuiaPersonal.observacion = '';
        },
    },
    computed: {
        computedForms() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer1;
        },
    },
    watch: {
        modalRegistrarGuiasPersonal: function (val) {
            if (val == false) {
                this.resetModalRegistrarGuiasPersonal();
            }
        },
        modalActualizarGuiasPersonal: function(val){
            if(val == false){
                this.resetModalActualizarGuiasPersonal();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            this.listarUsuarios();
            this.listarTipoPersonal();
            this.listarGuiaPersonal();
        }
    }
}
</script>